import React, { useState, useEffect, useContext, createElement } from "react"
import { useSearchParams, useParams } from "react-router-dom"
import { useAudit } from "../audit"
import LayoutScreen from "../components/Layout"
import { DFL } from "../components/DFL"
import { useStateWithSessionStorage } from "../hooks"
import ModalContext from "../modal"
import useResponsive from "../responsive"
import images from "../img/index"
import { useNavigate, useLocation } from "react-router-dom"

import { AssessmentButton, RadioSelectList, RadioList } from "../components/AssessmentComponents"
import { BaseModal } from "../components/MoreInfo"
import { AuthWrapper } from '../components/AuthWrapper'
import { googleAuth, appleAuth } from "../components/Auth"
import { BaseScreen, KickoutScreen } from "../components/Assessment"
import { AssessmentConfirmingModal } from "../components/AssessmentConfirmingModal"
import { useSelector } from "react-redux"
// import { skipPrompt } from "../prompt"

import "../css/LandingPage.css"
import "../css/Outcome.css"
import "../css/Typography.css"
import "../css/Button.css"

function formatConditions(condition) {
  return adbuTranslation[condition]
}

const adbuTranslation = {
  heart_attack: "You have had a heart attack",
  stroke: "You have had a stroke",
  heart_surgery_or_procedure: "You have had a heart surgery or heart procedure",
  breathlessness_with_activity: "You have had breathlessness or chest pain with activity",
  dizziness_fainting: "You have had dizziness or fainting when you stand up",
  unexplained_indigestion: "You have had unexplained indigestion",
  angina_or_chest_pain: "You have had angina or chest pain",
  irregular_heartbeat: "You have had irregular heartbeat",
  heart_valve_issue: "You have had heart valve issues ",
  uncontrolled_high_blood_pressure: "You have had uncontrolled high blood pressure",
  low_blood_pressure: "You have had low blood pressure",
  sudden_loss_of_vision: "You have experienced sudden loss of vision",
  sudden_loss_of_hearing: "You have experienced sudden loss of hearing",
  sickle_cell_anemia: "You have had sickle cell anemia",
  multiple_myeloma: "You have had multiple myeloma",
  leukemia: "You have had leukemia",
  bleeding_problems: "You have had bleeding problems",
  stomach_ulcers: "You have had stomach ulcers",
  erectionLongerThanFourHours: "You have had an erection longer than four hours",
  otherEDMedication: "You are using another ED treatment",
  over75: "You are older than 75 years old",
  enlargedProstate: "You take a prescription medicine for an enlarged prostate (BPH)",
  adbuPeyronies: "You have a deformed penis shape (Peyronie's Disease)",
  highBloodPressure: "You take medicine for high blood pressure",
}

// adding this back for adbu / post-assessment
export function AdbuList({ answers }) {
  const flags = {
    over75: answers.age > 75,
    adbuQuestions1: answers.adbuQuestions1.length !== 0,
    adbuQuestions2: answers.adbuQuestions2.length !== 0,
    adbuQuestions3: answers.adbuQuestions3.length !== 0,
    adbuQuestions4: answers.adbuQuestions4.length !== 0,
    erectionLongerThanFourHours: answers.erectionLongerThanFourHours === "yes",
    adbuNonCardiac1: answers.adbuNonCardiac1.length !== 0,
    adbuPeyronies: answers.adbuPeyronies === "yes",
    adbuNonCardiac2: answers.adbuNonCardiac2.length !== 0,
    adbuNonCardiac3: answers.adbuNonCardiac3.length !== 0,
    // adopbu questions that will be diverted here if the above are true
    otherEDMedication: answers.otherEDMedication === "yes",
    highBloodPressure: answers.highBloodPressure === "yes",
    enlargedProstate: answers.enlargedProstate === "yes",
  }
  const conditions = []
  if (flags.over75) {
    conditions.push("over75")
  }
  if (flags.adbuQuestions1) {
    conditions.push(...answers.adbuQuestions1)
  }
  if (flags.adbuQuestions2) {
    conditions.push(...answers.adbuQuestions2)
  }
  if (flags.adbuQuestions3) {
    conditions.push(...answers.adbuQuestions3)
  }
  if (flags.adbuQuestions4) {
    conditions.push(...answers.adbuQuestions4)
  }
  if (flags.adbuNonCardiac1) {
    conditions.push(...answers.adbuNonCardiac1)
  }
  if (flags.adbuNonCardiac2) {
    conditions.push(...answers.adbuNonCardiac2)
  }
  if (flags.adbuNonCardiac3) {
    conditions.push(...answers.adbuNonCardiac3)
  }
  if (flags.erectionLongerThanFourHours) {
    conditions.push("erectionLongerThanFourHours")
  }
  if (flags.adbuPeyronies) {
    conditions.push("adbuPeyronies")
  }
  if (flags.otherEDMedication) {
    conditions.push("otherEDMedication")
  }
  if (flags.highBloodPressure) {
    conditions.push("highBloodPressure")
  }
  if (flags.enlargedProstate) {
    conditions.push("enlargedProstate")
  }

  return (
    <ul className="adbuList">
      {conditions.length > 0 && conditions.map((condition, index) => <li key={index}>{formatConditions(condition)}</li>)}
    </ul>
  )
}

export function AdopbuList({ answers }) {
  const flags = {
    otherEDMedication: answers.otherEDMedication === "yes",
    highBloodPressure: answers.highBloodPressure === "yes",
    enlargedProstate: answers.enlargedProstate === "yes",
  }
  const conditions = []
  if (flags.otherEDMedication) {
    conditions.push("You are using another ED treatment")
  }
  if (flags.highBloodPressure) {
    conditions.push("You are currently taking a medicine for high blood pressure")
  }
  if (flags.enlargedProstate) {
    conditions.push("You take a prescription medicine for an enlarged prostate (BPH)")
  }

  return (
    <ul className="adbuList">
      {conditions.length > 0 && conditions.map((condition, index) => <li key={index}>{condition}</li>)}
    </ul>
  )
}

export function AdbuScreen({ answers, onConfirm, handleReadDFL }) {
  const setModal = useContext(ModalContext)
  const onEvent = useAudit({ type: "OUTCOME_ADBU_DISPLAYED" })
  const [showKickout, setShowKickout] = useState(false)

  if (showKickout) {
    return (
      <KickoutScreen
        id="kickout-id"
        title="Based on your answers, you must speak with a doctor before purchasing Avanafil OTC.<br/><br/>If a doctor says it is appropriate for you to use Avanafil OTC, please come back to purchase."
        heroImage="../img/adbu.png"
        onEvent={onEvent}
        fromAdbuScreen={true}
        showStopSignImage={false}
      />
    )
  }

  return (
    <div className="outcome-content">
      <p className="content">
        Based on your answers, you should talk to a doctor about whether Avanafil OTC is appropriate for you to use because:
      </p>
      <AdbuList answers={answers} />
      <p className="content">Has a doctor said it is appropriate for you to use Avanafil OTC?</p>
      <div className="assessment-buttons-container">
        <AssessmentButton
          className="btn primary outline rect"
          onClick={() => {
            // On 'Yes' click, set the modal to ADBUConfirmModal
            onEvent({ type: "OUTCOME_ADBU_ASKED_DOCTOR_ATTESTATION_YES" })
            setModal(() => (
              <ADBUConfirmModal
                onConfirm={() => {
                  onEvent({
                    type: "OUTCOME_ADBU_CONFIRM_SPOKE_TO_DOCTOR_CLICKED",
                  })
                  answers.askedDoctor = "yes"
                  setModal()
                  onConfirm()
                }}
              />
            ))
          }}
        >
          Yes
        </AssessmentButton>
        <AssessmentButton
          className="btn primary outline rect"
          onClick={() => {
            onEvent({ type: "OUTCOME_ADBU_ASKED_DOCTOR_ATTESTATION_NO" })
            setShowKickout(true)
            answers.askedDoctor = "no"
          }}
        >
          No
        </AssessmentButton>
      </div>
    </div>
  )
}

export function AdopbuScreen({ answers, onConfirm, handleReadDFL, setShowDFL }) {
  const setModal = useContext(ModalContext)
  const onEvent = useAudit({ type: "OUTCOME_ADOPBU_DISPLAYED" })
  const [showKickout, setShowKickout] = useState(false)

  if (showKickout) {
    return (
      <KickoutScreen
        id="kickout-id"
        title="Based on your answers, you must speak with a doctor or pharmacist before purchasing Avanafil OTC.<br/><br/>If a doctor says it is appropriate for you to use Avanafil OTC, please come back to purchase."
        heroImage="../img/adbu.png"
        onEvent={onEvent}
        fromAdopbuScreen={true}
        showStopSignImage={false}
      />
    )
  }

  return (
    <div className="outcome-content">
      <p className="content">
        Based on your answers, you should talk to a doctor or pharmacist about whether Avanafil OTC is appropriate for you to use because:
      </p>
      <AdopbuList answers={answers} />
      <p className="content">Has a doctor or a pharmacist said it is appropriate for you to use?</p>
      <div className="assessment-buttons-container">
        <AssessmentButton
          className="btn primary outline rect"
          onClick={() => {
            // On 'Yes' click, set the modal to ADBUConfirmModal
            onEvent({
              type: "OUTCOME_ADOPBU_ASKED_DOCTOR_OR_PHARMACIST_ATTESTATION_YES",
            })
            setModal(() => (
              <ADOPBUConfirmModal
                onConfirm={() => {
                  onEvent({
                    type: "OUTCOME_ADOPBU_CONFIRM_ASKED_DOCTOR_OR_PHARMACIST_CLICKED",
                  })
                  setShowDFL(true) // After confirming in the modal, show the DFLScreen
                  answers.askedDoctorOrPharmacist = "yes"
                  setModal()
                  onConfirm()
                }}
                onGoBack={() => setModal()}
              />
            ))
          }}
        >
          Yes
        </AssessmentButton>
        <AssessmentButton
          className="btn primary outline rect"
          onClick={() => {
            onEvent({
              type: "OUTCOME_ADOPBU_ASKED_DOCTOR_OR_PHARMACIST_ATTESTATION_NO",
            })
            setShowKickout(true)
            answers.askedDoctorOrPharmacist = "no"
          }}
        >
          No
        </AssessmentButton>
      </div>
    </div>
  )
}


export function ADBUConfirmModal({ onConfirm, onGoBack }) {
  useAudit({ type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_DISPLAYED" })

  return (
    <AssessmentConfirmingModal
    handleClose={onGoBack}
    handleContinue={onConfirm}
    text="I confirm I have talked to a doctor, and they said it is appropriate for me to use."
  />
  )
}

export function ADOPBUConfirmModal({ onConfirm, onGoBack }) {
  useAudit({ type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_DISPLAYED" })

  return (
    <AssessmentConfirmingModal
      handleClose={onGoBack}
      handleContinue={onConfirm}
      text="I confirm I have talked to a doctor or pharmacist, and they said it is appropriate for me to use."
    />
  )
}

export function DFLConfirmModal({ onConfirm, onGoBack }) {
  useAudit({ type: "READ_DFL_NEXT_CLICKED" })

  return (
    <AssessmentConfirmingModal
      handleClose={onGoBack}
      handleContinue={onConfirm}
      text="I confirm that I have read the Drug Facts Label."
    />
  )
}

export function ReturningDoctorAttestationScreen({ setModal, value, onBack, onSubmit }) {
  const [_value, _setValue] = useState(value)
  const onEvent = useAudit({ type: "OUTCOME_DOCTOR_ATTESTATION_DISPLAYED" })

  useEffect(() => {
    _setValue(value)
  }, [value])

  const showAttestationModal = () => {
    setModal(() => (
      <ADBUConfirmModal
        onConfirm={() => {
          onEvent({
            type: "UPDATED_ANSWER",
            componentId: "doctorAttestationConfirm",
            newValue: "yes",
          })
          setModal()
          onSubmit({
            doctorAttestation: _value,
            doctorAttestationConfirm: "yes",
          })
        }}
        onGoBack={() => setModal()}
      />
    ))
  }

  return (
    <BaseScreen
      title="Did your doctor say Avanafil OTC is safe for you?"
      onSubmit={() => {
        _value === "yes"
          ? showAttestationModal()
          : onSubmit({
              doctorAttestation: _value,
            })
      }}
      disableContinue={!_value}
      showBackButton={true}
      onBack={onBack}
    >
      <RadioSelectList
        id="doctorAttestation"
        variant="primary"
        value={_value}
        options={[
          { title: "Yes", value: "yes" },
          { title: "No", value: "no" },
        ]}
        onEvent={(e) => {
          onEvent(e)
          if (e.type === "UPDATED_ANSWER") {
            _setValue(e.newValue)
          }
        }}
      />
    </BaseScreen>
  )
}

export function ReturningAskedDoctorScreen({ value, onBack, onSubmit }) {
  const [_value, _setValue] = useState(value)
  const onEvent = useAudit({ type: "OUTCOME_ASKED_DOCTOR_DISPLAYED" })

  useEffect(() => {
    _setValue(value)
  }, [value])

  return (
    <BaseScreen
      title="Did you ask a doctor if Avanafil OTC is safe for you?"
      onSubmit={() => {
        onSubmit({ askedDoctor: _value })
      }}
      disableContinue={!_value}
      showBackButton={true}
      onBack={onBack}
    >
      <RadioSelectList
        id="askedDoctor"
        variant="primary"
        value={_value}
        options={[
          { title: "Yes", value: "yes" },
          { title: "No", value: "no" },
        ]}
        onEvent={(e) => {
          onEvent(e)
          if (e.type === "UPDATED_ANSWER") {
            _setValue(e.newValue)
          }
        }}
      />
    </BaseScreen>
  )
}

// function ReturningFlow({ state = { outcome: "" }, onReturnEdit, assessmentId }) {
// 	const { answers } = state
// 	const setModal = useContext(ModalContext)

// 	const adbu = answers.adbu

// 	const [currentReturningScreen, setCurrentReturningScreen] = useState(
// 		(() => {
// 			if (adbu) {
// 				return "asked"
// 			}
// 		})()
// 	)

// 	const body = (() => {
// 		if (currentReturningScreen === "asked") {
// 			return (
// 				<ReturningAskedDoctorScreen
// 					value={answers.askedDoctor || ""}
// 					onBack={() => {
// 						skipPrompt()
// 						router.push(`/return/${assessmentId}`)
// 					}}
// 					onSubmit={async ({ askedDoctor }) => {
// 						await onReturnEdit("returningAskedDoctorScreen", { askedDoctor })
// 						if (askedDoctor === "no") {
// 							setCurrentReturningScreen("asked/no")
// 						} else {
// 							setCurrentReturningScreen("attestation")
// 						}
// 					}}
// 				/>
// 			)
// 		}

// 		if (currentReturningScreen === "attestation") {
// 			return (
// 				<ReturningDoctorAttestationScreen
// 					setModal={setModal}
// 					value={answers.doctorAttestation || ""}
// 					onBack={() => {
// 						setCurrentReturningScreen("asked")
// 					}}
// 					onSubmit={async ({ doctorAttestation, doctorAttestationConfirm }) => {
// 						await onReturnEdit("doctorAttestation", {
// 							doctorAttestation,
// 							doctorAttestationConfirm,
// 						})
// 					}}
// 				/>
// 			)
// 		}

// 		return null
// 	})()

// 	return (
// 		<LayoutScreen showProfile={true} white={currentReturningScreen === "asked/no"}>
// 			{body}
// 		</LayoutScreen>
// 	)
// }

export function DFLScreen({ onRead, state = { outcome: "" }, wrapInLayout = true }) {
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false)
  const setModal = useContext(ModalContext)
  const onEvent = useAudit({ type: "READ_DFL_CONFIRM_MODAL_DISPLAYED" })

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement?.scrollTop || document.body.scrollTop
      const scrollHeight = document.documentElement?.scrollHeight || document.body.scrollHeight
      const clientHeight = document.documentElement?.clientHeight || window.innerHeight
      const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight

      setHasScrolledToBottom(scrolledToBottom)
    }

    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const showDFLReadModal = () => {
    setModal(() => (
      <DFLConfirmModal
        onConfirm={() => {
          onEvent({ type: "READ_DFL_CONFIRM_MODAL_CLICKED" })
          onRead()
          setModal()
        }}
        onGoBack={() => setModal()}
      />
    ))
  }

  const content = (
    <div id="outcome">
      <div className="dfl-content-wrapper">
        <p className="body-large">Be sure to read the Drug Facts Label (DFL) below.</p>
        <p className="body-large">
          This provides key information about how to use Avanafil OTC, including dose, warnings and side effects.
        </p>
        <DFL />
        <div className="dfl-btn-container">
          <button className="btn secondary" onClick={showDFLReadModal} disabled={!hasScrolledToBottom}>
            Next
          </button>
        </div>
      </div>
    </div>
  )

  return wrapInLayout ? <LayoutScreen stickyFooter={true}>{content}</LayoutScreen> : content
}

export function PurchaseScreen({ state = { outcome: "" }, wrapInLayout = true }) {
  // pull the assessment id out of the url
  const { assessmentId } = useParams()
  // State to keep track of the current screen (for example)
  const [currentScreen, setCurrentScreen] = useState("PurchaseScreen")

  useEffect(() => {
    window.scrollTo(0, 0)
    // Set the current screen state when the component is mounted
    // console.log('useEffect assessmentID', assessmentId)
    setCurrentScreen("PurchaseScreen")
  }, [])

  const [selectedOption, setSelectedOption] = useState("")
  const navigate = useNavigate()
  // console.log('PurchaseScreen mounted. Current Screen: ' + currentScreen);

  // Define the onEvent function
  const onEvent = useAudit({ type: "PURCHASE_SCREEN_DISPLAYED" })

  const handleOptionChange = (newValue) => {
    setSelectedOption(newValue)
    // Trigger the onEvent function
    onEvent({ type: "UPDATED_PURCHASE_OPTION", newValue })
  }

  const handleNextClick = () => {
    const state = { shouldSkipPrompt: true }
    if (selectedOption === "buy-in-store") {
        navigate(`/qr/${assessmentId}` + window.location.search, { state })
    } else if (selectedOption === "buy-online") {
        navigate(`/checkout/${assessmentId}`, { state })
    }
}

  const radioOptions = [
    { value: "buy-online", title: "Buy Online" },
    { value: "buy-in-store", title: "Buy in Store" },
  ]

  const content = (
    <div className="purchase-content">
      <div className="body-large">Based on your answers, Avanafil OTC is appropriate for you. Please use as directed.</div>
      <div className="radio-list-wrapper">
        <RadioList name="purchaseOption" options={radioOptions} value={selectedOption} onSelect={handleOptionChange} />
      </div>
      <button className="btn secondary" onClick={handleNextClick} disabled={!selectedOption}>
        Next
      </button>
    </div>
  )

  return wrapInLayout ? <LayoutScreen>{content}</LayoutScreen> : content
}

function OutcomeFlow({ state = { outcome: "" }, onReturnEdit, referenceNumberComponent }) {
  const { answers } = state
  // const setModal = useContext(ModalContext)
  const adbu = answers.adbu
  const adopbu = answers.adopbu
  const [inputting, setInputting] = useStateWithSessionStorage("OutcomeFlow_inputting", false)
  const { desktop } = useResponsive()
  // DFL Rendering
  const [showDFL, setShowDFL] = useStateWithSessionStorage("OutcomeFlow_showDFL", false)
  const [readDFL, setReadDFL] = useStateWithSessionStorage("OutcomeFlow_readDFL", false)

  // const location = useLocation()

  async function handleOnConfirm() {
    if (answers.askedDoctor === "yes") {
      await onReturnEdit("AskedDoctorScreen", {
        askedDoctor: answers.askedDoctor,
      })
      setShowDFL(true)
    }

    if (answers.askedDoctorOrPharmacist === "yes") {
      await onReturnEdit("AskedDoctorOrPharmacistScreen", {
        askedDoctorOrPharmacist: answers.askedDoctorOrPharmacist,
      })
      setShowDFL(true)
    }
  }

  const handleReadDFL = () => {
    setReadDFL(true)
    setShowDFL(false)
  }

  const body = (() => {
    if (showDFL) {
      return <DFLScreen wrapInLayout={false} onRead={handleReadDFL} />
    }

    if (adopbu && !adbu) {
      if (!answers.askedDoctorOrPharmacist) {
        return (
          <AdopbuScreen
            answers={answers}
            onConfirm={handleOnConfirm}
            setShowDFL={setShowDFL}
            handleReadDFL={handleReadDFL}
          />
        )
      } else if (answers.askedDoctorOrPharmacist === "yes" && readDFL) {
        return <PurchaseScreen wrapInLayout={false}/>
      }
    }

    if (adbu || adopbu) {
      if (!answers.askedDoctor) {
        return (
          <AdbuScreen answers={answers} onConfirm={handleOnConfirm} setShowDFL={setShowDFL} handleReadDFL={handleReadDFL} />
        )
      } else if (answers.askedDoctor === "yes" && readDFL) {
        return <PurchaseScreen wrapInLayout={false} />
      }
    }

    return null
  })()

  return (
    <LayoutScreen
      stickyFooter={true}
      heroSrc={(answers.askedDoctor && !desktop) || inputting ? "" : images.caution}
      showProfile={true}
      white={answers.askedDoctor && !desktop}
    >
      {body}
      {!inputting && createElement(referenceNumberComponent, {}, [])}
    </LayoutScreen>
  )
}

function SuccessFlow({ state = { outcome: "" }, onReturnEdit, referenceNumberComponent }) {
  // const { answers } = state
  // const setModal = useContext(ModalContext)
  const { desktop } = useResponsive()
  // DFL Rendering
  // do we still need it?
  // const [readDFL, setReadDFL] = useStateWithSessionStorage("OutcomeFlow_readDFL", false)
  const [readDFL, setReadDFL] = useState(sessionStorage.getItem("dflRead") || false)

  const handleReadDFL = () => {
    setReadDFL(true)
    sessionStorage.setItem("dflRead", true)
  }

  const body = (() => {
    if (readDFL) {
      return <PurchaseScreen wrapInLayout={false} />
    }

    return <DFLScreen wrapInLayout={false} onRead={handleReadDFL} />
  })()

  return (
    <LayoutScreen stickyFooter={true} showProfile={true} white={!desktop}>
      {body}
      {createElement(referenceNumberComponent, {}, [])}
    </LayoutScreen>
  )
}

// Parent component to everything on outcome page
export default function OutcomePage(props) {
  const { outcome } = props.state
  const { desktop } = useResponsive()

  const storedValue = sessionStorage.getItem("OutcomeFlow_showSignInScreen")
  const hasStoredValue = storedValue !== null ? JSON.parse(storedValue) : true

  const [showSignInScreen, setShowSignInScreen] = useState(hasStoredValue)

  // sign in screen moved here from summary screen so that kickout can be calculated first
  if (showSignInScreen) {
    return (
      <LayoutScreen showProfile={false}>
        <div className="outcome-auth-wrapper">
            <AuthWrapper 
              onApple={async () => {
                await appleAuth("signup", true)
                sessionStorage.setItem("OutcomeFlow_showSignInScreen", false)
                setShowSignInScreen(false)
              }}
              onGoogle={async () => {
                await googleAuth("signup", true)
                sessionStorage.setItem("OutcomeFlow_showSignInScreen", false)
                setShowSignInScreen(false)
              }}
              onGuest={async () => {
                sessionStorage.setItem("OutcomeFlow_showSignInScreen", false)
                setShowSignInScreen(false)
              }}
              desktop={desktop}
              assessmentState="outcome"
            />
        </div>
      </LayoutScreen>
    )
  }

  if (outcome === "ok") {
    return <SuccessFlow {...props} />
  }

  return <OutcomeFlow {...props} />
}
