// Libraries
import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';

// Components
import LayoutScreen from '../components/Layout';
import { CheckoutScreenTitle } from '../components/AssessmentComponents';
import { Card, ConfirmationCard, CardText, CardSpan, clamp, CheckoutStepSubtitle, Dollars } from '../components/CheckoutComponents'
import { SelectProduct } from './checkout/SelectProduct';
import { ShippingAddress } from './checkout/ShippingInformation';
import ShippingMethod from './checkout/ShippingMethod';
import { PaymentMethod } from './checkout/PaymentInformation';
import Progress, { SmoothProgress } from '../components/Progress.js';
import images from '../img'
import icons from "../icn/index"

// Utilities
import useResponsive from '../responsive.js';
import { checkoutClient } from '../client';
import history from '../history';
import { usePrompt } from "../prompt"

// Styles
import "../css/Checkout.css";
import "../css/Typography.css";

// CSS
import "../css/Checkout.css"
import "../css/Typography.css"

const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');

function ViewOrder({productName, cost, subtotal, shipping, shippingMethodTitle, shippingCost, tax, amount, firstName, lastName, line1, line2,
  city, state, postal_code, country, brand, last4, editable=false, onPurchase, goto}) {
  const {mobile, tablet, desktop} = useResponsive()
  
  const cards = {
    visa: images['card-visa'],
    amex: images['card-amex'],
    mastercard: images['card-mastercard'],
    discover: images['card-discover'],
  }

  const cardImage = cards[brand] ? <img src={cards[brand]} alt={brand} style={{ width: "52px", height: "auto" }} /> : null;

  return (
    <div style={{display: "flex", gap: "8px", flexDirection: mobile || tablet ? "column" : "row" }}>
      <div style={{flex: "auto", display: "flex", flexDirection: "column", gap: "8px", minWidth: "250px"}}>
        <Card header="Shipping Address" editable={editable} onEdit={() => goto("address")}>
          <CardText>{firstName} {lastName}</CardText>
          <CardText>{line1}, {line2}</CardText>
          {/* {line2 && <CardText>{line2}</CardText>} */}
          <CardText>{city}, {state} {postal_code}</CardText>
        </Card>
        <Card header="Shipping Method" editable={editable} onEdit={() => goto("shipping_method")}>
          <CardText>{shippingMethodTitle}</CardText>
          {/* <CardText>Arrives by Dec 1, 2022</CardText> */}
        </Card>
        <Card header="Payment Method" editable={editable} onEdit={() => goto("payment_method")}>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {cardImage}
            <CardText>Ending in {last4}</CardText>
          </div>
        </Card>
      </div>
      <div style={{flex: "auto"}}>
        <Card header="Order Summary" style={{height: "100%"}} editable={editable} onEdit={() => goto("product")}>
          <OrderSummary productName={productName} subtotal={subtotal} shippingCost={shippingCost} tax={tax} amount={amount} postalCode={postal_code} />
        </Card>
      </div>
    </div>
  )
}

function ViewConfirmation({productName, cost, subtotal, shipping, shippingMethodTitle, shippingCost, tax, amount, firstName, lastName, line1, line2,
  city, state, postal_code, country, brand, last4, editable=false, onPurchase, goto}) {
  const {mobile, tablet, desktop} = useResponsive()
  
  const cards = {
    visa: images['card-visa'],
    amex: images['card-amex'],
    mastercard: images['card-mastercard'],
    discover: images['card-discover'],
  }

  const cardImage = cards[brand] ? <img src={cards[brand]} alt={brand} style={{ width: "52px", height: "auto" }} /> : null;

  return (
    <div style={{display: "flex", gap: "8px", flexDirection: mobile || tablet ? "column" : "row" }}>
      <div style={{flex: "auto", display: "flex", flexDirection: "column", gap: "8px", minWidth: "250px"}}>
        <ConfirmationCard header="Shipping Address" editable={editable} onEdit={() => goto("address")}>
          <CardText>{firstName} {lastName}</CardText>
          <CardText>{line1}, {line2}</CardText>
          {/* {line2 && <CardText>{line2}</CardText>} */}
          <CardText>{city}, {state} {postal_code}</CardText>
        </ConfirmationCard>
        <ConfirmationCard header="Shipping Method" editable={editable} onEdit={() => goto("shipping_method")}>
          <CardText>{shippingMethodTitle}</CardText>
          {/* <CardText>Arrives by Dec 1, 2022</CardText> */}
        </ConfirmationCard>
        <ConfirmationCard header="Payment Method" editable={editable} onEdit={() => goto("payment_method")}>
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {cardImage}
            <CardText>Ending in {last4}</CardText>
          </div>
        </ConfirmationCard>
      </div>
      <div style={{flex: "auto"}}>
        <ConfirmationCard header="Order Summary" style={{height: "100%"}} editable={editable} onEdit={() => goto("product")}>
          <OrderSummary productName={productName} subtotal={subtotal} shippingCost={shippingCost} tax={tax} amount={amount} postalCode={postal_code} />
        </ConfirmationCard>
      </div>
    </div>
  )
}

function OrderSummary({productName, cost, subtotal, shipping, shippingCost, tax, amount, postalCode}) {
  const style = {
    li: {
      listStyleType: "none", fontWeight: 600,
    },
    rowItem: {
      display: "flex", justifyContent: "space-between", alignItems: "center", 
    },
    total: {
      fontSize: "28px", lineHeight: "38px", color: 'var(--primary)', fontWeight: "bold"
    },
    price: {
      fontWeight: "bold"
    },
    taxWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center", // Aligns children (text) in the center vertically
      height: "100%", // Ensures the wrapper takes full height of its container
    }
  }

  return (
    <div>
      <ul className="order-summary-list" style={{margin: 0, padding: 0, listStyleType: "none"}}>
        <li style={{...style.li}}>
          <div style={style.rowItem}>
            <CardSpan>{productName}</CardSpan>
            <CardSpan style={style.price}><Dollars cents={subtotal} /></CardSpan>
          </div>
        </li>
        <li style={style.li}>
          <div style={style.rowItem}>
            <CardSpan>Subtotal (1 item)</CardSpan>
            <CardSpan style={style.price}><Dollars cents={subtotal} /></CardSpan>
          </div>
        </li>
        <li style={style.li}>
          <div style={style.rowItem}>
            <CardSpan>Shipping</CardSpan>
            <CardSpan style={style.price}><Dollars cents={shippingCost} /></CardSpan>
          </div>
        </li>
        <li style={style.li}>
          <div style={style.rowItem}>
            <div style={style.taxWrapper}>
              <CardSpan>Estimated Tax</CardSpan>
              <CardSpan>(Tax for {postalCode})</CardSpan>
            </div>
            <CardSpan style={style.price}><Dollars cents={tax} /></CardSpan>
          </div>
        </li>
        <li>
          <hr />
          <div style={{...style.rowItem, ...style.total}}>
            <span>Total</span>
            <span><Dollars cents={amount} /></span>
          </div>
        </li>
      </ul>
    </div>
  )
}

function PreviewOrder({cost, address, shippingMethod, shippingCost, paymentMethodDetails, goto}) {
  const selectedProduct = JSON.parse(sessionStorage.getItem('selectedProduct')) || {}
  const shippingAddress = JSON.parse(sessionStorage.getItem('shippingAddress')) || {}
  const {subtotal, shipping, tax, amount} = cost
  // Alias "description" as "productName" when destructuring
  const { description: productName, price } = selectedProduct  
  const { firstname: firstName, lastname: lastName, line1, line2, city, state, zip: postal_code, country = "US" } = shippingAddress
  const {brand, last4} = paymentMethodDetails
  
  return (
    <div>
      <ViewOrder 
        productName={productName}
        goto={goto}
        editable={true}
        subtotal={price}
        shipping={shipping}
        shippingCost={shippingMethod.cost}
        shippingMethodTitle={shippingMethod.title}
        cost={cost}
        tax={tax}
        amount={amount}
        firstName={firstName}
        lastName={lastName}
        line1={line1}
        line2={line2}
        city={city}
        state={state}
        postal_code={postal_code}
        country={country}
        brand={brand}
        last4={last4}/>
    </div>
  )
}

function OrderConfirmation({cost, address, shippingMethod, shippingCost, paymentMethodDetails}) {
  const selectedProduct = JSON.parse(sessionStorage.getItem('selectedProduct')) || {}
  const shippingAddress = JSON.parse(sessionStorage.getItem('shippingAddress')) || {}
  const {subtotal, shipping, tax, amount} = cost
  // Alias "description" as "productName" when destructuring
  const { description: productName, price } = selectedProduct  
  const { firstname: firstName, lastname: lastName, line1, line2, city, state, zip: postal_code, country = "US" } = shippingAddress
  const {brand, last4} = paymentMethodDetails
  
  return (
    <div>
      <p className="body-regular-bold">You have successfully placed your order. Your order number is <b className="success-text">abc-12345</b>.</p>
      <ViewConfirmation 
        productName={productName}
        goto={() => {}}
        editable={false}
        subtotal={subtotal}
        shipping={shipping}
        shippingMethodTitle={shippingMethod.title}
        shippingCost={shippingMethod.cost}
        tax={tax}
        amount={amount}
        firstName={firstName}
        lastName={lastName}
        line1={line1}
        line2={line2}
        city={city}
        state={state}
        postal_code={postal_code}
        country={country}
        brand={brand}
        last4={last4}/>
        <ViewOrderTextWithIcon />
    </div>
  )
}

const BUTTON_STATES = {
  HIDDEN: 'HIDDEN',
  DISABLED: 'DISABLED',
  ENABLED: 'ENABLED',
  LOADING: 'LOADING'
}

export default function Checkout() {
  const [customerId, setCustomerId] = useState('')
  const [clientSecret, setClientSecret] = useState('')

  useEffect(() => {
    checkoutClient.createSetupIntent({})
      .then(resp => {
        setCustomerId(resp.customerId)
        setClientSecret(resp.clientSecret)
      })
  }, [])

  const graphikNormal = {
    family: "Graphik",
    src: 'url("./fonts/Graphik-Regular.otf") format("opentype")',
    weight: "normal",
    style: "normal",
  }


  const options = {
    clientSecret,
    appearance: {
      variables: {
        colorBackground: "#FFFFFF",
        colorPrimary: "#001e46",
        colorText: "#272727",
        colorDanger: "#ED0000",
        spacingUnit: "7px",
      },
      rules: {
        ".Input::placeholder": {
          color: "transparent",
        },
      },
    },
    fonts: [graphikNormal],
  }

  return (clientSecret && customerId) ? (
    <Elements options={options} stripe={stripePromise}>
      <CheckoutInner customerId={customerId} clientSecret={clientSecret} />
    </Elements>
  ) : <LayoutScreen showProfile={true} />

}

function calculateTotalCost(subtotal, shipping, tax) {
  // Convert all inputs to numbers to ensure correct addition
  const total = Number(subtotal) + Number(shipping) + Number(tax);
  return total;
}

// TODO: Replace this with the data we'll receive from the backend (user's saved addresses)
const mockupAddresses = [
  // {
  //   firstName: "charlie", 
  //   lastName: "brown",
  //   line1: "123 street",
  //   line2: "",
  //   city: "Houston",
  //   state: "TX",
  //   postal_code: "12345",
  // },
  // {
  //   firstName: "Avanafil",  
  //   lastName: "Customer",
  //   line1: "1315 Walnut Street",
  //   line2: " Suite 932",
  //   city: "Philadelphia",
  //   state: "PA",
  //   postal_code: "19107",
  // }
]
  // TODO: Add links and replace <span> with <a> tags once we integrate with Stripe
  const PolicyDisclaimer = ({text}) => {
    return (
      <h6 className="payment-policy-text">
        *By clicking the <b>{text}</b> button you agree to our <span className='policy-link'>Terms</span> and <span className='policy-link'>Privacy</span> policies.
      </h6>
    )
  } 

const CheckoutInner = ({ customerId, clientSecret }) => {
  const initialIndex = parseInt(sessionStorage.getItem('checkoutStep'), 10) || 0
  const [index, setIndex] = useState(initialIndex)
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState(JSON.parse(sessionStorage.getItem('selectedProduct')) || {})
  const [paymentMethod, setPaymentMethod] = useState('')
  const [address, setAddress] = useState(JSON.parse(sessionStorage.getItem('shippingAddress')) || {})
  const [isAddressComplete, setIsAddressComplete] = useState(() => {
    const storedAddress = JSON.parse(sessionStorage.getItem('shippingAddress'));
    return storedAddress?.complete || false; // Use optional chaining to safely access the complete flag
  });  
  const [selectedAddress, setSelectedAddress] = useState({})
  const [shippingMethod, setShippingMethod] = useState(JSON.parse(sessionStorage.getItem('shippingMethod')) || { id: '', value: '', title: '', rightText: '', cost: 0 })
  const [paymentAddressSame, setPaymentAddressSame] = useState(true)
  const [paymentMethodSelect, setPaymentMethodSelect] = useState('')
  const [paymentIntentSecret, setPaymentIntentSecret] = useState('')
  const [cost, setCost] = useState(() => {
    const savedCost = sessionStorage.getItem('cost')
    return savedCost ? JSON.parse(savedCost) : { loaded: false, subtotal: 0, shipping: 0, tax: 159, amount: 0 }
  })
    const [paymentFormComplete, setPaymentFormComplete] = useState(false)
  const [paymentMethodDetails, setPaymentMethodDetails] = useState({ brand: '', last4: '' })
  const [mode, setMode] = useState('NORMAL')
  const [stepData, setStepData] = useState({
    product: {},
    address: {},
    shippingMethod: {},
    paymentMethod: '',
  })
  const [billingAddress, setBillingAddress] = useState(JSON.parse(sessionStorage.getItem('billingAddress')) || {})
  const previewOrderScreenIndex = 4
  const confirmationScreenIndex = 5
  const allAddressesSaved = address.complete ? [...mockupAddresses, address] : mockupAddresses
  const [savedAddresses, setSavedAddresses] = useState(allAddressesSaved)

  // Update isAddressComplete based on sessionStorage changes
  useEffect(() => {
    const intervalId = setInterval(() => {
      const storedAddress = JSON.parse(sessionStorage.getItem('shippingAddress'));
      if (storedAddress?.complete !== isAddressComplete) {
        setIsAddressComplete(storedAddress?.complete); // Update the state if there's a change
      }
    }, 500); // Check every 500 milliseconds for changes

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, [isAddressComplete]);


  const [backButtonState, setBackButtonState] = useState(BUTTON_STATES.ENABLED)
  const [nextButtonState, setNextButtonState] = useState(BUTTON_STATES.DISABLED)

  useEffect(() => {
    const savedStep = sessionStorage.getItem('checkoutStep')
    if (savedStep !== null) {
      setIndex(parseInt(savedStep, 10))
    }
  }, [])

  useEffect(() => {
    sessionStorage.setItem('checkoutStep', index.toString())
  }, [index])

  // Function to handle select product selection
  const handleSelectProduct = (selectedProduct) => {
    setProduct(selectedProduct)
    setCost({
      ...cost,
      subtotal: selectedProduct.price,
      amount: calculateTotalCost(selectedProduct.price, cost.shipping, cost.tax),
    })
    sessionStorage.setItem('subtotal', JSON.stringify(selectedProduct.price))
    sessionStorage.setItem('amount', JSON.stringify(calculateTotalCost(selectedProduct.price, cost.shipping, cost.tax)))
  }

  useEffect(() => {
    sessionStorage.setItem('selectedProduct', JSON.stringify(product))
  }, [product])

  const handleSelectShippingMethod = (selectedShippingMethod) => {
    if (!selectedShippingMethod) return
    const shippingCost = selectedShippingMethod.cost
    setShippingMethod(selectedShippingMethod)
    const updatedAmount = calculateTotalCost(cost.subtotal, shippingCost, cost.tax)
    setCost({
      ...cost,
      shipping: shippingCost,
      amount: updatedAmount,
    })
    sessionStorage.setItem('amount', JSON.stringify(updatedAmount))
  }
  
  const handleAddressChange = (updatedAddress) => {
    setAddress(updatedAddress) // Update local state
    // Store the updated address in session storage in real-time
    sessionStorage.setItem('shippingAddress', JSON.stringify(updatedAddress))
  }

  useEffect(() => {
    const savedAddress = JSON.parse(sessionStorage.getItem('shippingAddress'))
    if (savedAddress) {
      setAddress(savedAddress)
    }
  }, [])

  useEffect(() => {
    // Store the updated address in session storage
    sessionStorage.setItem('shippingAddress', JSON.stringify(address))
  }, [address])

  useEffect(() => {
    sessionStorage.setItem('shippingMethod', JSON.stringify(shippingMethod))
  }, [shippingMethod])

  useEffect(() => {
    sessionStorage.setItem('paymentMethod', paymentMethod)
  }, [paymentMethod])

  useEffect(() => {
    const savedAmount = sessionStorage.getItem('amount')
    const savedSubtotal = sessionStorage.getItem('subtotal')
    if (savedAmount) {
      setCost(prevCost => ({
        ...prevCost,
        amount: JSON.parse(savedAmount),
        subtotal: savedSubtotal ? JSON.parse(savedSubtotal) : prevCost.subtotal,
      }))
    }
  }, [])
  

  const progressSteps = [
    "Select Product",
    "Shipping Address",
    "Shipping Method",
    "Payment Info",
    "Preview Order",
    "Order Confirmation"
  ]

  const screenTitles = [
    "Select Product",
    "Shipping Address",
    "Shipping Method",
    "Payment Information",
    "Preview Order",
    "Order Confirmation"
  ]

  const isConfirmationOrderPage = screenTitles[index] === "Order Confirmation"

  usePrompt("one", true, () => {}, "checkout", isConfirmationOrderPage)

  useEffect(() => {
    const fetchPaymentMethod = async () => {
      if (paymentMethod) {
        try {
          const { id, last4, brand } = await checkoutClient.getPaymentMethod({ id: paymentMethod })
          setPaymentMethodDetails({ loaded: true, id, last4, brand })
        } catch (error) {
          console.error("Failed to fetch payment method:", error)
        }
      }
    }
    fetchPaymentMethod()
  }, [paymentMethod])

  const stripe = useStripe()
  const elements = useElements()

  const createPaymentIntent = async (customerId, paymentMethod, productId) => {
    const resp = await checkoutClient.createPaymentIntent({customerId, paymentMethod, productId})
    const {clientSecret, subtotal, shipping, tax, amount} = resp
    setPaymentIntentSecret(clientSecret)
    setCost({loaded: true, subtotal, shipping, tax, amount})
  }

  const goto = id => {
    const index = screens.findIndex(s => s.id === id)
    if (index >= 0) {
      setIndex(index)
    }
  }

  const edit = id => {
    setMode('EDIT')
    sessionStorage.setItem('isEditing', 'true')
    goto(id)
  }

  const isUserAuthenticated = false
  const hasSavedShippingAddress = false
  const confirmedAddress = isUserAuthenticated ? selectedAddress : address

  const screens = [
    { id: "product", jsx: <SelectProduct key="products" products={products} value={product} onSelect={handleSelectProduct} /> },
    { id: "address", jsx: <ShippingAddress key="address" address={address} onChange={handleAddressChange} setNextButtonState={setNextButtonState} /> },
    { id: "shipping_method", jsx: <ShippingMethod key="shipping_method" value={shippingMethod} onSelect={handleSelectShippingMethod} /> },
    {
      id: "payment_method",
      jsx: (
        <PaymentMethod 
          key="payment_method"
          value={paymentMethodSelect}
          address={confirmedAddress}
          paymentAddressSame={paymentAddressSame}
          onCheck={(v) => setPaymentAddressSame(v)}
          onPaymentInfo={setPaymentFormComplete}
          setPaymentMethodDetails={setPaymentMethodDetails}
          onCompleteChange={setPaymentMethodSelect}
          billingAddress={billingAddress}
          onChangeBillingAddress={setBillingAddress}
          setNextButtonState={setNextButtonState}
        />
      ),
    },
    {
      id: "preview",
      jsx: <PreviewOrder key="preview" productName={product.description} cost={cost} address={confirmedAddress} shippingMethod={shippingMethod} paymentMethodDetails={paymentMethodDetails} goto={edit} />,
    },
    {
      id: "confirmation",
      jsx: <OrderConfirmation key="confirmation" productName={product.description} cost={cost} address={confirmedAddress} shippingMethod={shippingMethod} paymentMethodDetails={paymentMethodDetails} />
    }
  ]

  const currentStep = index + 1
  const totalSteps = screens.length
  const cur = screens[index].id

  const hasSelectedShippingMethod = Boolean(shippingMethod?.value)

  const ready = {
    product: !!product.id,
    address: isAddressComplete,
    shipping_method: hasSelectedShippingMethod,
    payment_method: !!paymentMethodSelect && paymentFormComplete,
    preview: true,
  };

  useEffect(() => {
    console.log('ready:', ready)

    if (ready[cur]) {
      setNextButtonState(BUTTON_STATES.ENABLED)
    } else {
      setNextButtonState(BUTTON_STATES.DISABLED)
    }
  }, [cur, ...Object.values(ready)])

  useEffect(() => {
    checkoutClient.getProducts({})
      .then(resp => {
        setProducts(resp.products)
      })
  }, [product])

  useEffect(() => {
    if (products.length > 0 && !product) {
      setProduct(products[0])
    }
  }, [products])

  useEffect(() => {
    if (cur === "preview") {
      setBackButtonState(BUTTON_STATES.HIDDEN)
    }

    if (cur === "confirmation") {
      setBackButtonState(BUTTON_STATES.HIDDEN)
      setNextButtonState(BUTTON_STATES.HIDDEN)
    }
  }, [cur])

  const previewIndex = screens.findIndex(s => s.id === "preview")

  const getNextIndex = () => {
    if (mode === "NORMAL") {
      return clamp(0, index+1, screens-1)
    }
    if (mode === "EDIT") {
      if (cur === "preview") {
        return clamp(0, index+1, screens-1)
      }
      return previewIndex
    }
  }

  const nextIndex = getNextIndex()

  const submitProduct = async () => {
    if (product.id) {
      if (mode === "EDIT" && paymentMethod) {
        await createPaymentIntent(customerId, paymentMethod, product.id)
      }
      setIndex(nextIndex)
    }
  }

  const submitAddress = () => {
    if (address.complete || isAddressComplete) {
      setIndex(nextIndex)
    }
  };

  const submitShippingMethod = () => {
    if (shippingMethod) {
      setIndex(nextIndex)
    }
  }

  const submitPaymentMethodSelect = () => {
    if (paymentMethodSelect) {
      setIndex(nextIndex)
    }
  }

  const submitPayment = async () => {
    if (!stripe || !elements) {
      return
    }
    if (paymentMethod) {
      setIndex(nextIndex)
      return
    }
    let _address
    if (paymentAddressSame) {
      _address = address
    } else {
      _address = billingAddress
      _address.postal_code = _address.zip
    }
    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            city: _address.city,
            country: "US",
            line1: _address.line1,
            line2: _address.line2,
            postal_code: _address.postal_code,
            state: _address.state
          }
        },
      },
      return_url: window.location.origin + window.location.pathname
    })
    if (result.error) {
      alert('error')
      console.log('confirmSetup error', result.error)
      return
    }
    if (result.setupIntent) {
      const paymentMethod = result.setupIntent.payment_method
      setPaymentMethod(paymentMethod)
      await createPaymentIntent(customerId, paymentMethod, product.id)
      setIndex(nextIndex)
      return
    }
  }

  const purchase = async () => {
    setIndex(nextIndex)
  }
  
  const nextHandlers = {
    async product() {
      await submitProduct()
    },
    async address() {
      await submitAddress()
    },
    async shipping_method() {
      await submitShippingMethod()
    },
    async payment_method() {
      await submitPaymentMethodSelect()
    },
    async preview() {
      await purchase()
    }
  }

  const back = () => {
    const isEditing = sessionStorage.getItem('isEditing') === 'true'
  
    if (isEditing) {
      sessionStorage.removeItem('isEditing')
      setIndex(previewOrderScreenIndex) 
      return
    }
    
    // Default back behavior if not in EDIT mode
    if (index === 0) {
      history.go(-1)
      return
    }
    
    setIndex(index => Math.max(0, index - 1))
  }

  const next = async () => {
    setNextButtonState(BUTTON_STATES.LOADING)
    
    // Check if the user was in edit mode
    const isEditing = sessionStorage.getItem('isEditing') === 'true'
  
    if (isEditing) {
      // If editing, always go back to the Preview Order screen
      setIndex(previewOrderScreenIndex)
      sessionStorage.removeItem('isEditing') // Clear the flag
    } else {
      // Normal flow if not editing
      const handler = nextHandlers[cur]
      await handler()
    }
  
    setNextButtonState(BUTTON_STATES.ENABLED)
  }

  const nextText = {
    payment_method: "Preview Order",
    preview: "Purchase",
  }

  const { mobile, desktop } = useResponsive()
  const disclaimer = (
    <div
      style={{ display: "flex", justifyContent: "left", marginTop: "12px" }}
    >
      <p
        style={{
          color: 'var(--primary)',
          fontSize: "16px",
          lineHeight: "24px",
          maxWidth: mobile ? "255px" : undefined,
          textAlign: "left",
          fontWeight: 700,
        }}
      >
        *By clicking The <b>Purchase</b> button, You agree to Stripe <span className="link">Terms</span> and <span className="link">Privacy</span> policies. 
      </p>
    </div>
  )

  return (
    <div style={{flex: "auto", display: "flex", flexDirection: "column"}}>
      <LayoutScreen showProfile={true} showBackButton={index !== previewOrderScreenIndex && index !== confirmationScreenIndex} onBack={back} displayTextBtn={false}>
        <div style={{maxWidth: "890px", margin:"0 auto", paddingTop:"24px"}}>
          <div style={{padding: "24px"}} id={screens[index].id}>
            <div style={{marginBottom: "64px"}}>
              <AdjustedProgress cur={index} steps={progressSteps} displayName={desktop}/>
            </div>
            <CheckoutScreenTitle text={screenTitles[currentStep-1]} className="screen-title"/>
            <CheckoutStepSubtitle 
              currentStep={currentStep} 
              totalSteps={totalSteps} 
              isMobile={mobile}
            />
            <Carousel cur={index}>
              {screens.map(s => s.jsx)}
            </Carousel>
            <div style={{paddingTop: "48px"}}>
              <Button onClick={next} styleClass="checkout-confirm-btn" state={nextButtonState}>{nextText[cur] || "Next"}</Button>
              {cur === "preview" && <PolicyDisclaimer text="purchase" />}
              { cur === "payment_method" && <PolicyDisclaimer text="preview order" /> }
            </div> 
          </div>
        </div>
      </LayoutScreen>
    </div>
  )
}

function AdjustedProgress({ cur, steps, displayName=true }) {
  // HACK: this is to map the current screen index to the corresponding progress bar step that should be shown
  // (they don't map 1 to 1)
  const m = {
    0: 0,
    1: 1,	    
    2: 2,	    
    3: 3,	    
    4: 4,	    
    5: 5,	    
    6: 6

  }
  cur = m[cur]
  return <Progress cur={cur} steps={steps} displayName={displayName}/> 
}

function Button({onClick, children, state, outline=false, styleClass=''}) {
  const className = `btn ${outline ? 'outline' : ''} ${styleClass}`

  if (state === BUTTON_STATES.HIDDEN) {
    return null
  }

  let disabled = state === BUTTON_STATES.DISABLED

  if (state === BUTTON_STATES.LOADING) {
    disabled = true
    children = 'loading...'
  }
 
  return (
    <button className={className} onClick={onClick} disabled={disabled}>{children}</button>
  )
}

function ViewOrderTextWithIcon() {
  return (
    <div style={{
      fontFamily: 'Open Sans',
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '36px',
      letterSpacing: '-0.011em',
      textAlign: 'center',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '24px',
      color: '#0035F2',
    }}>
      View Receipt
      <img src={icons["link"]} alt="Link" style={{ marginRight: '8px', marginLeft: '8px' }} />
    </div>
  );
}

// We removed this to simply the carousel process however, 
// it wasn't saving state. Added this back in to fix temporarily.
function Carousel({children=[], cur=0}) {
  return (
    <div>
      {
        children.map((c, i) => (
          <div key={i} style={{display: i === cur ? undefined : "none"}}>{c}</div>
        ))
      }
    </div>
  )
}